<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="loading['processEditor/getProcesses']"
            @edit="onEdit"
            @delete="onDelete"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { ProcessModel } from '@/modules/process-editor/models/process-editor-model';

    const { fields } = ProcessModel;

    export default {
        name: 'ProcessEditorListTable',
        data() {
            return {
                presenter: ProcessModel.presenter,
                fields: [
                    fields.display_name,
                    fields.description,
                    fields.status.extendField({ labelType: true }),
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'processEditor/processList/rows',
                loading: 'shared/loading',
                processEditorPermissions: 'processEditor/processEditorPermissions',
            }),
            actions() {
                return [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        permission: this.processEditorPermissions.hasPermissionToEdit
                    },
                    {
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        type: 'danger',
                        permission: this.processEditorPermissions.hasPermissionToDestroy
                    },
                ]
            }
        },
        methods: {
            ...mapActions({
                doRemove: 'processEditor/processForm/remove',
            }),
            onEdit(data) {
                this.$router.push({
                    name: 'processView',
                    params: {
                        id: data.item.id,
                    },
                });
            },
            onDelete(process) {
                this.doRemove(process.item.id)
                .then(() => {
                    this.$emit('updateProcess');
                })
            }
        },
    };
</script>
