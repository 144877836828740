<template>
    <st-page
        :title="$t('PROCESS_EDITOR.HEADER')"
    >
        <template #toolbar>
            <st-button
                v-can:create="'processEditor'"
                variant="secondary"
                :callback="addProcess"
            >
                <i class="fa fa-plus"></i>
                {{$t('PROCESS_EDITOR.NEW_PROCESS_BTN')}}
            </st-button>
        </template>
        <process-editor-list-filter ref="listProcessEditorFilter">
            <process-editor-list-table @updateProcess="updateProcess"/>
        </process-editor-list-filter>
    </st-page>
</template>

<script>
    import ProcessEditorListFilter from '../components/ProcessEditorListFilter';
    import ProcessEditorListTable from '../components/ProcessEditorListTable';

    export default {
        name: 'ProcessEditorList',
        components: {
            ProcessEditorListFilter,
            ProcessEditorListTable,
        },
        methods: {
            addProcess() {
                this.$router.push({ name: 'processNew' });
            },
            updateProcess() {
                this.$refs.listProcessEditorFilter.refresh();
            },
        }
    }
</script>
