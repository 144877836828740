<template>
    <st-filters-pagination
        :header="$t('PROCESS_EDITOR.GENERAL.HEADER_LIST')"
        :total="total"
        stateModule="processEditor/processList"
        :filters="filters"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ProcessModel } from '@/modules/process-editor/models/process-editor-model';

    const { fields } = ProcessModel;

    const filterSchema = new FilterSchema([
        fields.display_name,
    ]);

    export default {
        name: 'ProcessEditorListFilter',
        computed: {
            ...mapGetters({
               total: 'processEditor/processList/total',
            }),
            filters() {
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'processEditor/processList/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },
    };
</script>
